
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.navbar-default {
  background-color: #009688;
  border-color: #d3e0e9;
}

.navbar-default .navbar-brand {
  color: #fff;
  font-size: 20px;
}

.navbar-default .navbar-brand:hover, .navbar-default .navbar-brand:focus {
  color: #fff;
  background-color: transparent;
}

.navbar-default .navbar-nav > li > a, .navbar-default .navbar-nav > li > a:hover {
  color: #fff;
  font-weight: bold;
}

.navbar-default .navbar-nav > .open > a, .navbar-default .navbar-nav > .open > a:hover, .navbar-default .navbar-nav > .open > a:focus {
  background-color: #009688;
  color: #fff;
}

.mt-5px {
  margin-top: 5px;
}

.img-btn {
  display: block;
  margin: auto;
}

.img-btn-text {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
  color: #838383;
}

.over-btn-default {
  border-color: #f1f1f1;
  box-shadow: 0 3px 0 0 #eaeaea;
}

.btn-default.over-btn-default:hover, .btn-default.over-btn-default.active {
  background: #ffffff;
  box-shadow: 0 5px 0 5 #eaeaea;
  border-color: #f1f1f1;
}

.btn-default.over-btn-default:active, .btn-default.over-btn-default.active {
  background: #ffffff;
  box-shadow: none;
  margin-bottom: -3px;
  margin-top: 3px;
}

.btn-default.over-btn-default:focus, .btn-default.over-btn-default.active {
  background: #ffffff;
  box-shadow: 0 5px 0 5 #eaeaea;
  border-color: #f1f1f1;
}